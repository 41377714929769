import React, { useState, useRef, useEffect } from 'react';
import './Course.css';
import { useParams, useHistory } from 'react-router-dom';
import { Input, Button, Progress, Alert, Select } from 'antd';
import { Form } from 'antd';
import {createSandbox, getCloudRedirect} from './sandbox';
import { Navbar } from './Navbar';
import { Header } from './Header';
import Iframe from 'react-iframe';
import * as config from './config';
import {ICourseInfo} from "./Home";
import useSWR, {preload} from "swr";
import {getCallbackOrigin} from "./cloudSandboxUtil";


const apiUrl = '/api/v1/sandbox';
const fetcher = (url: string) => fetch(url).then((res) => res.json());
preload('/api/v1/sandbox/courses.json', fetcher);

export const Course = (props:any) => {
  const { data, isLoading } = useSWR<ICourseInfo[]>(`${apiUrl}/courses.json`, fetcher);
  const qs = new URLSearchParams(window.location.search);
  let { id } = useParams<{id: string}>();
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<Error|null>(null);
  const [email, setEmail] = useState<string|null>(qs.get('email'));
  const DEFAULT_TTL_IN_SECONDS = '86400';
  const [ttl, setTtl] = useState<string>(qs.get('ttl') || DEFAULT_TTL_IN_SECONDS);
  const [verificationCode, setVerificationCode] = useState<string|null>(qs.get('code'));
  const [starting, setStarting] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const fromDemo = config.demoHosts.includes(window.location.hostname);

  const [form] = Form.useForm();
  const refHostGet = (): string => {
    // console.log(`Doc: ${JSON.stringify(document)}`);

    if ( document?.referrer ) {
      const referrer = new URL(document.referrer.toString())

      //const referer = new URL(req.headers.referer.toString());
      return referrer.hostname;
    } else if ( window.location?.hostname ) {
      return window.location.hostname;
    } else {
      return "localtest.me";
    }
  }
  const refHost = refHostGet();

  // define startSandbox() for later use...
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startSandbox = async () => {
    console.log(`RefHost: ${refHost}`);
    let localProgress = 0;
    const updateProgress = setInterval(() => {
      localProgress = localProgress + 100/sec;
      setProgress(localProgress);
    }, 1000);
    let host;
  
    try {
      if (course?.cloud === true) {
        const criblCloudHost: string = await getCloudRedirect(id);
        const callbackOrigin: string = getCallbackOrigin(window.location.origin);
        window.location.href = `${criblCloudHost}?sandboxID=${course.id}&sandboxCallbackUrl=${encodeURI(`${callbackOrigin}/callback`)}`;
        return;
      } else {
        host = await createSandbox(email || '', id, sec, verificationCode || '', ttl, refHost);
      }
    } catch (err) {
      clearInterval(updateProgress);
      if (err instanceof Error) {
        setError(err);
      }
      setProgress(0);
      setStarting(false);
      return;
    }
    history.push(`/ui/`, {
      id,
      host,
      email,
      course,
      ttl,
      });
  }
  
  if (email) {
    form.setFieldsValue({email});
  }

  if (fromDemo && ttl) {
    form.setFieldsValue({ttl});
  }

  if (verificationCode) {
    form.setFieldsValue({verificationCode});
  }

  const course = data?.find(c => c.id===id);

  useEffect(() => {
    if (isLoading) {
      return
    }
    // We must use a somewhat hacky method of kicking off the API call to avoid creating side effects
    // We validate that everything has been filled out and set using set* hook methods
    // Then if they're all set properly, we will kick off startSandbox()

    // 404 handling, send the user to the home page
    if (!course) {
      history.replace("/");
    }

    // Special case to redirect users
    // Clicking the back button will take to previous page without knowing the course id was invalid
    if (course?.type === 'redirect') {
      history.replace(course.target as string);
    }

    // Email set, haven't started and we're not errored
    if (starting && (email || course?.cloud) && !error) {
      setStarting(false);
      const ttlSet = (fromDemo && ttl) || !fromDemo; // ttl only gets set on the demo page
      const verificationCodeSet = verificationCode || fromDemo // Verification Code only gets set on sandbox page
      const skipValidation = config.noValidateTypes.includes(course?.type || '') || config.noValidateSites.includes(refHost) || course?.cloud // CCOE and other sites skip all the form fields

      // Course page not from demo and no verification code filled out
      if (!fromDemo && !verificationCode && !skipValidation) {
        fetch(`${apiUrl}/requesttoken`, {
          method: 'POST',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
        .then(response => {
          if (!response.ok) {
            return response.text().then(errText => { throw new Error(errText) });
          }
        })
        .catch(err => { 
          if (err instanceof Error) {
            setError(err);
            setStarting(false);
          }
        });
      // From Demo and TTL selected or no TTL selected on course page
      // Verification Code is filled out or we're in the Demo page
      // Certain course types or certain sites we don't even care what's filled out
      } else if ((ttlSet && verificationCodeSet) || skipValidation) {
        startSandbox();
      }
    }
  }, [email, error, starting, fromDemo, ttl, verificationCode, course, refHost, startSandbox, history, isLoading]);

  const sec = course?.startupTime|| 180;
  const min = Math.floor(sec/60);
  const submit = (values: any) => {
    if (values.email) {
      setEmail(values.email);
    }
    if (values.verificationCode) {
      setVerificationCode(values.verificationCode);
    }
    if (fromDemo && values.ttl) {
      setTtl(values.ttl);
    }
    setError(null);
    setProgress(0);
    setStarting(true);
  };
  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required!',
    types: {
      email: 'Please enter a valid email address.',
    }
  };

  let buttonText = 'Get Verification Code';

  if (email || config.noValidateTypes.includes(course?.type || '') || config.noValidateSites.includes(refHost) || course?.cloud) {
    buttonText = (fromDemo ? 'Start Demo' : 'Start Sandbox');
  }

  const ttlopts = [
    { value: '28800', label: "8 Hours"},
    { value: '43200', label: "12 Hours"},
    { value: '86400', label: "1 day"},
    { value: '604800', label: "1 week"}
  ];

  return <div ref={containerRef} className="course__container">
    <Navbar />
    <Header
      backUrl="/"
      backLabel={ fromDemo ? "Back to Demos" : "Back to Sandboxes"} 
      description={
        <div dangerouslySetInnerHTML={{ __html: course?.description || '' }} />
      }
      imageUrl={course?.imageUrl}
      imageWidth={course?.imageWidth}

    >
      <Form form={form} onFinish={submit} layout='inline' className="course__form" name="sandbox" validateMessages={validateMessages} 
          initialValues={{ email: qs.get('email'), ttl, verificationCode: qs.get('code') }}>
        { !course?.cloud &&
        <Form.Item name={'email'} label="Email" rules={[{ pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$|^\w+-\w+-\w+-\w+-\w+/, required: true, message: "Please enter a valid email address."}]}>
          <Input style={{width:300, marginRight:5, marginBottom: 10}} placeholder="Email address" name={"email"} value={email || ''}/>
        </Form.Item>
        }
        { fromDemo &&
        <Form.Item name={'ttl'} label="Time to Live">
          <Select options={ttlopts} />
        </Form.Item>}
        {
          email && ! config.noValidateTypes.includes(course?.type || '') && !config.noValidateSites.includes(refHost) && !course?.cloud &&
          <Form.Item name={'verificationCode'} label="Verification Code">
            <Input style={{width:300, marginRight:5, marginBottom: 10}} placeholder="Verification code" value={verificationCode || ''}/>
          </Form.Item>
        }
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={ progress===0 && error != null }>{buttonText}</Button>
        </Form.Item>
      </Form>
      { 
        (email && !verificationCode && !error  && ! config.noValidateTypes.includes(course?.type || '')) && !config.noValidateSites.includes(refHost) && !course?.cloud &&
        <div className="course__verificationCode">Check your email for a verification code!</div>
      }
      {
        progress>0 &&
        <div className="course__progress">
          <span>{fromDemo ? 'Demo' : 'Sandbox'} starting... Expected time: {min} minutes</span>
          <Progress percent={Math.round(progress)} status="active" />
        </div>
      }
      {
        error &&
        <Alert type="error" showIcon message={fromDemo ? `Error occurred while creating demo: ${error.message}` : `Error occurred while creating sandbox: ${error.message}`} />
      }
    </Header>
    <div id='previewheader' className='wrapped'><h1>Course Preview</h1></div>
    <div id='iframeContainer'>
      <Iframe url={`/coursedocs/${course?.id}/docs/intro1`}
        id="docsIframe"
        height="100%"
        width="100%"
      />
    </div>
  </div>;
}
